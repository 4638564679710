.NavigationItems {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  height: 100%;
  flex-flow: column;
  justify-content: space-evenly;
}

@media (min-width: 500px) {
  .NavigationItems {
    flex-flow: row;
  }

  .MobileOnlyDiv {
    display: none;
  }
}

@media (max-width: 500px) {
  .Search {
    width: 95%;
    margin: 10px 0;
    font-size: 1.2rem;
    font-weight: bold;
  }
  /* lines btween menu items on small screens */
  .MobileOnlyDiv {
    width: 100%;
    height: .5px;
    background-color: #888;
  }
}

@media(max-width: 675px) {
  .Search {
    margin: 10px 0;
    font-size: 1.2rem;
    font-weight: bold;
  }
}