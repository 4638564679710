.Footer {
  color: white;
  background-color: #222;
  border-top: 1px solid #E7E7E7;
  box-sizing: border-box;
  padding: 7px;
  left: 0;
  bottom: 0;
  min-height: 60px;
  font-size: .8rem;
}

.Footer a {
  text-decoration: underline;
  color: inherit;
}

.Footer p {
  margin: 0;
}

.FooterContact {
  text-align: left;
}

.FooterCopyright {
  text-align: center;
}

.FooterNetCom {
  text-align: right;
}

.phantom {
display: block;
padding: 20px;
height: 60px;
max-width: 100%;
}

@media (max-width: 675px) {
  .FooterContact{
    text-align: center;
    margin-bottom: 3px;
  }
}