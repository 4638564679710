.NavigationItem {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
  font-size: 1.2rem;
  font-weight: bold;
}

.NavigationItem a {
  color: #222;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active, 
.NavigationItem a.active {
  color: #000;
}

/* Desktop */
@media (min-width: 500px) {
  .NavigationItem {
    margin: 0;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
  }
  
  .NavigationItem a {
    color: #222;
    height: 100%;
    width: auto;
    padding: 16px 10px;
    border-bottom: 4px solid transparent;
  }
  
  .NavigationItem a:hover,
  .NavigationItem a:active, 
  .NavigationItem a:focus, 
  .NavigationItem a.active {
    color: #000;
  } 
}

/* Mobile */
@media(max-width: 500px) {
  .NavigationItem {
    width: 95%;
  }
}
