.FormResponse {
  margin-top: 1.5rem;
  border: 1px solid #ccc;
  border-radius: .15rem;
  font-family: inherit;
  max-width: 100%;
  padding: 10px;
  font-size: 1.5rem;

}

.Success h4 {
  color: #4cb444;
}

.FormResponse p {
  text-align: center;
  color: #212121;
}

.Failed {
  color: red;
}