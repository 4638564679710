.Part {
  width: 80%;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 0.15rem;
  box-shadow: 0 2px 3px #ccc;
  padding: 10px 10px;
  box-sizing: border-box;
  margin: 5px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Info {
  width: 100%;

}

a {
  text-decoration: none;
}

@media (max-width: 600px) {
  .Part{
    width: 100%;
  }
}

@media (max-width: 700px) {
  .Part{
    flex-flow: column;
  }
}