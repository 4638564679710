.ReqError {
  margin-top: 1px;
  color: red;
  text-align: left;
}

.CapContainer {
  width: 75%;
  transform: scale(1);
}

@media (max-width: 300px) {
  .CapContainer {
    transform: scale(.7);
  }
}

@media (max-width: 400px) {
  .CapContainer {
    transform: scale(.8);
  }
}