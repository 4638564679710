.FormContainer {
  width: 60%;
  margin: auto;
}

.BackButtonContainer {
  max-width: 150px;
}

.Call {
  padding-top: 20px;
  text-align: center;
}

.Call h3 {
  margin: 1px;
}

.ReqError {
  font-weight: bold;
  color: red;
  text-align: left;
}

.CapContainer {
  width: 75%;
  transform: scale(1);
}

@media (max-width: 300px) {
  .CapContainer {
    transform: scale(.7);
  }
}

@media (max-width: 400px) {
  .CapContainer {
    transform: scale(.8);
  }
}

@media (max-width: 600px) {
  .FormContainer{
    width: 90%;
  }
  .BackButtonContainer {
    max-width: 100%;
  }
}

@media (max-width: 700px) {
  .FormContainer{
    flex-flow: column;
  }
}
