.SearchWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -7px;
}

.SearchIcon {
  position: absolute;
  pointer-events: none;
  padding: 3px 3px 3px 5px;
}

/* Mobile */
@media(max-width: 500px) {
  .SearchWrap{
    margin-top: 0;
  }
}