img {
  width: 100%;
}

.Content, .ContentLower {
  display: flex;

  margin-left: 5%;
  margin-right: 5%;
  font-size: 1.5rem;
}

.Content{
  justify-content: space-between;
}

.ContentLower{
  margin-top: 1.5rem;
  justify-content: space-between;
}

.Content p {
  margin: 0;
}

.ImgContainer {
  max-width: 50%;
}

.MapContainer {
  display: flex;
  width: 100%;
  max-width: 45%;
  height: 350px;
  max-height: 350px;
}

.ContactHeaderLower {
  flex: auto;
  margin-left:10px;
  font-size: 1rem;
  max-width: 50%;
}
.ContactHeader {
  
  margin-left: 10px;
}

.ContactHeader h3 {
  margin: 0;
}

@media (max-width: 675px) {
  .Content, .ContentLower {
    margin-left: 0;
    margin-right: 0;
    font-size: 1rem;
    flex-flow: column-reverse;
  }
  .ImgContainer, .MapContainer, .ContactHeaderLower {
    max-width: 100%;
  }
  .ContactHeader {
    text-align: center;
  }
  .ContactHeaderLower {
    margin: 5%;
  }
}

@media (min-width: 1024px) {
  .Content, .ContentLower {
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media (min-width: 1216px) {
  .Content, .ContentLower {
    margin-left: 10%;
    margin-right: 10%;
  }
}

