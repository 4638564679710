.Toolbar {
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f6c441;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 100;
}


.Logo {
  height: 80%;
}

@media (max-width: 675px) {
  .DesktopOnly {
    display: none;
  }
  .Toolbar {
    height: 60px;
  }
}

@media (min-width: 1024px) {
  .Logo {
    margin-left: 5%;
  }
  .DesktopOnly {
    margin-right: 5%;
  }
}

@media (min-width: 1216px) {
  .Logo {
    margin-left: 7%;
  }
  .DesktopOnly {
    margin-right: 10%;
  }
}