.FormDiv {
  padding-bottom: 15px;
}


.Input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.InputElement {
  outline: none;
  border: 1px solid #ccc;
  border-radius: .15rem;
  background-color: white;
  font: inherit;
  padding: 6px 10px;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.InputElement:focus {
  outline: none;
  border: 1px solid #EDBB6AA1;
  box-shadow: 0 0px 1px 1px #f6c441;
}

.Label {
  display: block;
}

/* input element invalid */
.Invalid {
  border: 1px solid red;
}
.Invalid:focus {
  border: 1px solid red;
  box-shadow: 0 0px 1px 1px #ff0000aa;
}
/* validation error message */
.Error {
  color: red;

}

.Search {
  padding-left: 27px;
}

.Textarea {
  resize: none;
}