/* .MenuButton {
  width: 35px;
  height: 80%;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  padding: 13px 0;
  box-sizing: border-box;
  cursor: pointer;
  top: 5%;
  background: transparent;
  border: none;
} */


/* .MenuButton div {
  width: 40px;
  height: 4px;
  background: #000;
  border-radius: 4px;
  position: relative;
  transition: all 0.3s linear;
  transform-origin: 1px;
} */

.MenuButton {
  margin: 1em;
  width: 35px;
}

.MenuButton:after, 
.MenuButton:before, 
.MenuButton div {
  background-color: #222;
  border-radius: 2px;
  content: '';
  display: block;
  height: 4px;
  margin: 5px 0;
  transition: all .2s ease-in-out;
}

.MenuButtonActive:before {
  transform: translateY(12px) rotate(135deg);
}

.MenuButtonActive:after {
  transform: translateY(-6px) rotate(-135deg);
}
.MenuButtonActive div {
  transform: scale(0);
}


@media (min-width: 675px) {
  .MenuButton {
    display: none;
  }
}