.Content {
  margin-top: 80px; /* height of Toolbar + desired <main> margin */
  /*min-height: 100vh;*/
  display: flex;
  flex-direction: column;
}

@media(max-width: 675px) {
  .Content {
    margin-top: 60px;
  }
}