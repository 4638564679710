.Content {
  margin-left: 5%;
  margin-right: 5%;
  font-size: 1.5rem;
}

.Content p {
  margin: 0;
  font-size: 1rem;
}

.ContentHeader {
  width: 100%;
}

.BioContainer {
  max-width: 100%;
  margin-bottom: 20px;
}

.ImgContainer {
  max-width: 100%;
}

@media (max-width: 675px) {
  .Content {
    margin-left: 1%;
    margin-right: 1%;
    font-size: 1rem;
    
  }
  .ContentHeader {
    text-align: center;
  }
}

@media (min-width: 1024px) {
  .Content {
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media (min-width: 1216px) {
  .Content {
    margin-left: 10%;
    margin-right: 10%;
  }
}