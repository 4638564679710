.Button {
  width: 80%;
  border: 1px solid;
  box-shadow: 0 2px 3px #ccc;
  padding: 10px 10px;
  box-sizing: border-box;
  margin: 5px auto;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  text-align: center;  
  outline: 0;
  font-family: inherit;
  font-weight: bold;
  cursor: pointer;
  background-color: #f6c441;
}

.Button::-moz-focus-inner {
  border: 0;
}

.Button:disabled {
  color: #ccc;
  background-color: #e2eaef;
}

.GetQuote {
  color: #000;
}

.Submit {
  color: #000;
}
