.Drawer {
  position: absolute;
  z-index: 90;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
  background-color: #f8f8f8;
  width: 100%;
  /*toolbar height*/
  top: 60px;
}


@media (min-width: 675px) {
  .Drawer {
    display: none;
  }
}

.Open {
  transform: translateY(0);
  position: fixed;
}

.Close {
  transform: translateY(-150%);
}