.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 89;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

@media (min-width: 675px) {
  .Backdrop {
    display: none;
  }
}