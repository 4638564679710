.Logo {
  width: 250px;
}

a:active,a:focus,a::-moz-focus-inner {
  border: 0;
  outline: none !important;
}


@media (max-width: 675px) {
  .Logo {
    width: 175px;
  }
}